import { useShopContext } from '@mediashop/app/hooks/useShopContext';
import { injectComponent } from '@mediashop/app/component-injector';
import FaviconSvgFr from '../../../assets/favicons/favicon_fr.svg?file';
import Favicon16Fr from '../../../assets/favicons/favicon-16x16_fr.png';
import Favicon32Fr from '../../../assets/favicons/favicon-32x32_fr.png';
import Favicon192Fr from '../../../assets/favicons/android-chrome-192x192_fr.png';
import Favicon512Fr from '../../../assets/favicons/android-chrome-512x512_fr.png';
import SafariPinnedTabSvgFr from '../../../assets/favicons/safari-pinned-tab_fr.svg?file';
import AppleTouchIconFr from '../../../assets/favicons/apple-touch-icon_fr.png';
import FaviconSvg from '../../../assets/favicons/favicon.svg?file';
import Favicon16 from '../../../assets/favicons/favicon-16x16.png';
import Favicon32 from '../../../assets/favicons/favicon-32x32.png';
import Favicon192 from '../../../assets/favicons/android-chrome-192x192.png';
import Favicon512 from '../../../assets/favicons/android-chrome-512x512.png';
import SafariPinnedTabSvg from '../../../assets/favicons/safari-pinned-tab.svg?file';
import AppleTouchIcon from '../../../assets/favicons/apple-touch-icon.png';
import FaviconIco from '../../../assets/favicons/favicon.ico';
import FaviconIcoFr from '../../../assets/favicons/favicon_fr.ico';
import { Helmet } from 'react-helmet-async';

/**
 * Emit favicons into html-head.
 */
function Favicon() {
    const { locale } = useShopContext();

    return (
        <Helmet>
            {locale === 'fr-CH' ? (
                <>
                    <link rel="icon" type="image/x-icon" href={FaviconIcoFr} />
                    <link rel="icon" type="image/svg+xml" href={FaviconSvgFr} />
                    <link rel="icon" type="image/png" sizes="16x16" href={Favicon16Fr} />
                    <link rel="icon" type="image/png" sizes="32x32" href={Favicon32Fr} />
                    <link rel="icon" type="image/png" sizes="192x192" href={Favicon192Fr} />
                    <link rel="icon" type="image/png" sizes="512x512" href={Favicon512Fr} />
                    <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIconFr} />
                    <link rel="mask-icon" color="#008bd2" href={SafariPinnedTabSvgFr} />
                </>
            ) : (
                <>
                    <link rel="icon" type="image/x-icon" href={FaviconIco} />
                    <link rel="icon" type="image/svg+xml" href={FaviconSvg} />
                    <link rel="icon" type="image/png" sizes="16x16" href={Favicon16} />
                    <link rel="icon" type="image/png" sizes="32x32" href={Favicon32} />
                    <link rel="icon" type="image/png" sizes="192x192" href={Favicon192} />
                    <link rel="icon" type="image/png" sizes="512x512" href={Favicon512} />
                    <link rel="apple-touch-icon" sizes="180x180" href={AppleTouchIcon} />
                    <link rel="mask-icon" color="#008bd2" href={SafariPinnedTabSvg} />
                </>
            )}
        </Helmet>
    );
}

export default injectComponent('pattern.template.Meta.Favicon', Favicon, 'haus-hobby');
