import Theme from '@mediashop/app/Theme';
import CatalogBaseTheme from '@mediashop/catalog-base';
import './pattern/template/Meta/index.tsx';

const HausHobbyTheme: Theme = {
    name: 'haus-hobby',
    extends: CatalogBaseTheme,
};

export default HausHobbyTheme;
