import { injectComponent } from '@mediashop/app/component-injector';
import { Helmet } from 'react-helmet-async';
import { helmetJsonLdProp } from 'react-schemaorg';
import { Corporation, WithContext } from 'schema-dts';
import { useProject } from '@mediashop/app/hooks/useProject';

function StructuredData() {
    const { shippingCountries, store } = useProject();

    const hausAndHobbySchemaCh: WithContext<Corporation> = {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'Mediashop Schweiz AG',
        alternateName: 'Haus & Hobby Schweiz',
        url: 'https://www.haus-hobby.ch/ch/de',
        logo: 'https://www.haus-hobby.ch/_img/100_100/site/binaries/content/gallery/images/haus-hobby/logos/hh_logo_desktop.svg',
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: '0554500148',
            contactType: 'customer service',
            areaServed: shippingCountries,
            availableLanguage: ['German', 'fr'],
        },
    };

    const hausAndHobbySchemaDeAt: WithContext<Corporation> = {
        '@context': 'https://schema.org',
        '@type': 'Corporation',
        name: 'Mediashop GmbH',
        alternateName: 'Haus & Hobby',
        url: 'https://www.haus-hobby.com/',
        logo: 'https://www.haus-hobby.com/_img/100_100/site/binaries/content/gallery/images/haus-hobby/logos/hh_logo_desktop.svg',
        contactPoint: {
            '@type': 'ContactPoint',
            telephone: '08004009920',
            contactType: 'customer service',
            areaServed: shippingCountries,
            availableLanguage: 'German',
        },
    };

    return (
        <Helmet
            script={[
                store === 'catalog_HaHo_ch' ? helmetJsonLdProp(hausAndHobbySchemaCh) : '',
                store === 'catalog_HaHo_de-at' ? helmetJsonLdProp(hausAndHobbySchemaDeAt) : '',
            ]}
        ></Helmet>
    );
}

export default injectComponent('pattern.template.Meta.StructuredData', StructuredData, 'haus-hobby');
